import React from "react";
import { graphql } from "gatsby";
import Seo from "../../components/Seo";
import Layout from "../../layouts/index";
import Img from "gatsby-image";
// import comment from '../../images/comment.png';
import ContactUs from "../../components/ContactUs";
import aws from "../../images/aws-1024x613.png";
import spkl from "../../images/Logo-e1573795725720.png";
import slr from "../../images/Blue-and-black-e1573037822635.png";
import skwirk from "../../images/skiwirklg.jpg";

const Cloudservices = (props) => {
  return (
    <Layout bodyClass="page-services">
      <Seo
        title="Cloud Services"
        description="iTelaSoft will match you to the right Cloud provider for your
                  organization. Working and integrating directly with all major
                  providers, including Amazon Web Services (AWS), our solutions
                  provide complete and highly flexible environments to best suit
                  your needs."
      />
      <div className="intro intro-banner" id="up">
        <div className="intro-banner-img">
          <Img
            fluid={props.data.bannerImg.childImageSharp.fluid}
            loading="eager"
            fadeIn={true}
            alt="Banner Image"
            className="hero-banner"
          />
        </div>
        <div className="container">
          <div className="row hero-section">
            <div className="col-12 wrap-page-title">
              <h1 className="page-title">iTelaSoft Cloud Services</h1>
              <hr />
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid wrap-section mb-4">
        <div className="container wrap-content">
          <div className="row">
            <div className="col-12 mt-4 text-center">
              <div className="discription-two pb-5">
                <p>
                  We will match you to the right Cloud provider for your
                  organization. Working and integrating directly with all major
                  providers, including Amazon Web Services (AWS), our solutions
                  provide complete and highly flexible environments to best suit
                  your needs. Depending on the business need and scale of the
                  organization, we deploy solutions in private cloud, public
                  cloud and hybrid cloud.
                </p>
              </div>
            </div>
          </div>
          <div className="what-we-offer text-center">
            <div className="row">
              <div className="col-12 pb-5">
                <h1 className="page-title">Why The Cloud?</h1>
                <hr />
                <p className="discription-two">
                  Through our cloud services, we will help your organization to
                  reduce capital/operational costs, speed up business
                  operations, enhance business outcomes, and grow your global
                  footprint. You will simplify collaboration while increasing
                  scalability for data-intensive applications.
                </p>
                <p className="discription-two">
                  Cloud-Native development is increasingly popular with most of
                  our clients. Its computing takes the best use of modern
                  techniques, such as PaaS, multi-cloud, microservices,
                  containers, CI/CD, and DevOps. The app can scale up and down
                  on demand and embrace the concepts of immutable
                  infrastructure.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container wrap-content">
        <div className="pb-6 section-2">
          <div className="row">
            <div className="col-12 pb-5">
              <h4 className="mb-3">Customer-Facing Web Applications</h4>
              <p className="discription-two">
                Due to the ease in manageability, web applications are gaining
                popularity. The Cloud is the ideal environment for this
                scenario. From rapid request increase to resource expansion, and
                connectivity and cost overruns issues, every possible scenario
                is covered. Developers need only focus on developing.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 pb-5">
              <h4 className="mb-3">Custom Business Applications</h4>
              <p className="discription-two">
                “Off the shelf” is not suitable in some scenarios. Sometimes it
                is necessary to have a custom solution that specifically caters
                to requirements. The technologies that back these custom
                solutions require specific environment needs that differ from
                traditional production environments. The cloud will work with
                your specific database and server, ensuring you are covered
                completely.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 pb-5">
              <h4 className="mb-3">Big Data and High-Performance Computing</h4>
              <p className="discription-two">
                To handle “Big” you need “Big” infrastructure, which is very
                hard to provision in a traditional scenario. Through the cloud,
                its availability of Terabytes in memory and its unmatched
                parallel processing capability smooths the operation cycle.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-4 pb-5">
              <img className="img-fluid p-3" alt="AWS" src={aws} />
            </div>
            <div className="col-12 col-lg-8 pb-5">
              <p className="discription-two">
                Amazon Web Services is the market leader in IaaS
                (Infrastructure-as-a-Service) and PaaS (Platform-as-a-Service)
                for cloud ecosystems, which can be combined to create a scalable
                cloud application without worrying about delays related to
                infrastructure provisioning and management.
              </p>
              <p className="discription-two">
                Our innovative solutions get your models to production faster
                with much less effort and lower cost, meaning the decision to
                work with iTelaSoft Cloud Managed Services is a decision to take
                your organization to new successes. Reach out today!
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row wrap-our-partners">
        <div className="col-12 mt-4">
          <h2 className="page-title text-center our-cl-title">Our Clients</h2>
          <div className="wrap-logos">
            <div className="client-logos">
              <img className="img-fluid" alt="speckle" src={spkl} />
              <img className="img-fluid" alt="SLR" src={slr} />
              <img className="img-fluid" alt="skwirk" src={skwirk} />
            </div>
          </div>
        </div>
      </div>
      <div className="row wrap-contact-us">
        <ContactUs />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    bannerImg: file(relativePath: { eq: "cloud-services-1024x597.png" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
export default Cloudservices;
